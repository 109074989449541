/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { unstable_noStore as noStore } from 'next/cache';
import dynamic from 'next/dynamic';
import _ from 'lodash';
import { ContentfulFlexiblePageBlock, ContentfulStream, ContentfulStreamSecurity } from '@/types/contentful';
import FlexiblePageBlock from '../flexible-page/FlexiblePageBlock';
const VideoPlayer = dynamic(() => import('@/components/-ui/video-player'), {
  ssr: false
});
type Props = {
  blocks?: ContentfulFlexiblePageBlock[];
  streams: ContentfulStream[];
  streamSecurity?: ContentfulStreamSecurity;
  accessCode: string;
  slug: string;
};
const PreviewPageContent: FC<Props> = ({
  blocks,
  streams,
  streamSecurity,
  accessCode,
  slug
}) => {
  noStore();
  return <>
      <div className="container-fluid mt-5 mt-lg-1" data-testid="previewWrapperTest">
        <div className="row">
          {streams?.map(event => <div className="p-0 preview-page-player-wrap" key={event?.id}>
              <VideoPlayer type="preview" dash={event?.dashUrl} hls={event?.hlsUrl} poster={streamSecurity?.poster?.asset?.file?.url ?? ''} trackingTitle={event.title} previewCredentials={{
            id: slug,
            accessCode
          }} />
            </div>)}
        </div>
      </div>

      {blocks?.map((block, index) => <FlexiblePageBlock key={`block${block.id}`} isFirstBlock={index === 0} block={block} showTransparentBackground={blocks.length > 1 || index % 2 !== 0} />)}
    </>;
};
export default PreviewPageContent;