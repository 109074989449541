'use client';

import { useEffect, useState } from 'react';
import type { FC } from 'react';
import dynamic from 'next/dynamic';
import { ContentfulImage, Event, EventStatus } from '@/types/contentful';
import { StreamError } from '@/types/event';
import Hero from '../../hero/HeroComponent';
import HeroEventConfigurator from '../../hero/HeroEventConfigurator';
import EventError from './EventError';
import FutureEventWithTicket from './FutureEventWithTicket';
import LiveEventSaleClosedComponent from './LiveEventSaleClosed';
import messages from './messages';
import PastEventNotPurchasedComponent from './PastEventNotPurchased';
import PastEventPurchasedComponent from './PastEventPurchased';
const VideoPlayer = dynamic(() => import('@/components/-ui/video-player'), {
  ssr: false
});
interface Props {
  event: Event;
  selectedVariantSku?: string;
  // Player and Hero related properties
  eventStatus?: EventStatus;
  doorsOpenDate?: Date;
  eventStart?: Date;
  duration?: number;
  isSaleOpen?: boolean;
  showEventConfigurator?: boolean;
  showPlayer?: boolean;
  showPlayerLoader?: boolean;
  showPlaceholder?: boolean;
  onPlayerError: (error: StreamError) => void;
  error?: StreamError;
  hasTicketInShopify?: boolean;
  hasAccess?: boolean;
  loggedIn?: boolean;
  stream?: {
    dashUrl?: string;
    hlsUrl?: string;
    dashCastUrl?: string;
  };
  backgroundImageProps: any;
  trackingTitle?: string;
  streamUrlsError?: boolean;
}
const HeroWithGeoIpAccess: FC<Props> = ({
  event,
  selectedVariantSku,
  duration,
  showPlaceholder,
  loggedIn = false,
  showPlayer = false,
  showEventConfigurator = false,
  showPlayerLoader = false,
  error,
  onPlayerError,
  hasTicketInShopify = false,
  hasAccess = false,
  isSaleOpen = false,
  eventStatus,
  doorsOpenDate,
  eventStart,
  stream: {
    dashUrl,
    hlsUrl
  } = {},
  backgroundImageProps,
  trackingTitle,
  streamUrlsError
}) => {
  // This logic makes sure we don't get hydration errors
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    setIsLoggedIn(loggedIn);
  }, [loggedIn]);
  return <>
      {showPlaceholder ? <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isCentered isEventHero>
          {isLoggedIn ? <div className="loading-event-info">
              <HeroEventConfigurator event={event} />
            </div> : undefined}
        </Hero> : undefined}
      {!!error && <EventError error={error} event={event} />}
      {streamUrlsError ? <EventError error={{
      code: 'NO_STREAM_URLS',
      name: messages.no_stream_urls.title,
      message: messages.no_stream_urls.message
    }} event={event} /> : (showPlayerLoader || showPlayer) && <div className="event-page__player-wrapper">
            {showPlayer && <VideoPlayer type="event" sku={selectedVariantSku} title={event.title} dash={dashUrl} hls={hlsUrl} poster={event.poster.asset?.file?.url} showLoader={showPlayerLoader} onError={onPlayerError} trackingTitle={trackingTitle} />}
          </div>}

      {showEventConfigurator && <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isEventHero>
          <HeroEventConfigurator event={event} hasTicketInShopify={hasTicketInShopify} />
        </Hero>}
      {!hasAccess && eventStatus === 'LIVE' && !isSaleOpen && !showPlayerLoader && <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isCentered isEventHero>
            <LiveEventSaleClosedComponent image={event.eventInfoLogo as ContentfulImage} loggedIn={loggedIn} />
          </Hero>}

      {hasTicketInShopify && eventStatus === 'FUTURE' && <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isCentered isEventHero>
          <FutureEventWithTicket title={event.title} description={event.shortDescription || ''} eventStart={eventStart} duration={duration} doorsOpenDate={doorsOpenDate} selectedVariantSku={selectedVariantSku} image={event.eventInfoLogo as ContentfulImage} />
        </Hero>}

      {eventStatus === 'PAST' && !hasTicketInShopify &&
    // NOT PURCHASED
    <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isCentered isEventHero>
            <PastEventPurchasedComponent image={event.eventInfoLogo as ContentfulImage} eventTitle={event.title} />
          </Hero>}

      {eventStatus === 'PAST' && hasTicketInShopify &&
    // PURCHASED
    <Hero backgroundImageProps={backgroundImageProps} colClassNames="col-12 col-md-8 col-lg-7 col-xl-5" isCentered isEventHero>
            <PastEventPurchasedComponent image={event.eventInfoLogo as ContentfulImage} eventTitle={event.title} />
          </Hero>}
    </>;
};
export default HeroWithGeoIpAccess;