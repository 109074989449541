import { FC, useEffect } from 'react';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import { useAppSelector } from '../../../state/hooks';
import { CartState } from '../../../state/reducers/cartSlice';
import { NormalizedCartListItem } from '../../../types/cart';
import CartListItem from './CartListItem';
type CartListProps = {
  normalizedCartList: Array<NormalizedCartListItem>;
};
const CartListComponent: FC<CartListProps> = ({
  normalizedCartList
}) => {
  const {
    status: cartStatus,
    removingLineId,
    addingAddonsId
  }: CartState = useAppSelector(state => state.cartSlice);
  const auth = useAppSelector(state => state.auth);
  const cartTotal = useAppSelector(state => state.cartSlice.cartTotalAmmount);
  const referrerUrl = localStorage.getItem('cartSlug');
  useEffect(() => {
    const impressions = normalizedCartList.map(({
      type,
      variantTitle,
      productTitle,
      shopifyVariantId,
      shopifyProductId,
      poster,
      price,
      compareAtPrice,
      sku
    }, index) => {
      return {
        id: sku,
        name: productTitle,
        brand: 'OnAir',
        category: type || 'Dummy category',
        variant: variantTitle || productTitle,
        price: price,
        compare_at_price: compareAtPrice || '0.0',
        quantity: '1',
        list: referrerUrl,
        product_id: shopifyProductId,
        variant_id: shopifyVariantId,
        image: poster.asset.url,
        url: window.location.href,
        position: index + 1 //position in cart indexed starting at 1
      };
    });
    pushToElevarDataLayer({
      eventName: 'dl_view_cart',
      auth: auth.token,
      cartTotal: cartTotal || '0',
      ecommerce: {
        currencyCode: 'EUR',
        actionField: {
          list: 'Shopping Cart'
        },
        impressions
      }
    });
  }, [referrerUrl]);
  return <>
      <div className="product-list">
        {normalizedCartList.map(({
        variantTitle,
        productTitle,
        shopifyVariantId,
        shopifyProductId,
        poster,
        eventStart,
        quantity,
        price,
        compareAtPrice,
        lineId,
        estimatedCost,
        isAddOnsProduct,
        icons,
        sku
      }, index) => <CartListItem sku={sku} productId={shopifyProductId} productTitle={productTitle} variantTitle={variantTitle} shopifyVariantId={shopifyVariantId} poster={poster} eventStart={eventStart} quantity={quantity} price={price} compareAtPrice={compareAtPrice} index={index} lineId={lineId} estimatedCost={estimatedCost} isAddOnsProduct={isAddOnsProduct} icons={icons} isLoading={cartStatus === 'REMOVE_CART_LINES_PENDING' && lineId === removingLineId || isAddOnsProduct && cartStatus === 'UPDATE_CART_LINES_PENDING' && addingAddonsId === shopifyVariantId} key={lineId} />)}
      </div>
    </>;
};
export default CartListComponent;