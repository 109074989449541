'use client';

import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { Event, EventStatus, EventVariant } from '@/types/contentful';
import { StreamError } from '@/types/event';
import { EventsByTag } from '@/types/page-context';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import { useAppSelector } from '../../../state/hooks';
import EventPageComponent from './EventPageComponent';
import { useEventConfiguratorProperties } from './useEventConfiguratorProperties';
import { useEventPageStatus } from './useEventPageStatus';
import { useStreamUrlsCheck } from './useStreamUrlsCheck';
type EventPageProps = {
  readonly location: Location;
  readonly event: Event;
  readonly eventsByTag: EventsByTag;
};
export const EventPage = ({
  event,
  eventsByTag
}: EventPageProps) => {
  const searchParams = useSearchParams();
  const isWatchTrailerParamSet = searchParams?.has('watch-trailer');
  const [selectedVariant, setSelectedVariant] = useState<EventVariant>();
  const [eventStatus, setEventStatus] = useState<EventStatus>();
  const [playerError, setPlayerError] = useState<StreamError | undefined>();
  const [referrerUrl, setReferrerUrl] = useState<string | null>(null);
  const auth = useAppSelector(state => state.auth);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedSlug = localStorage.getItem('slug');
      if (storedSlug) setReferrerUrl(storedSlug);
    }
  }, []);
  const {
    hasTicketInShopify,
    loggedIn,
    doorsOpenDate
  } = useEventPageStatus(event, setSelectedVariant, setEventStatus, selectedVariant, eventStatus);
  const {
    isShowRestrictedInCurrentCountry,
    partnerToAccessShowInCurrentCountry,
    stream,
    backgroundImageProps,
    countDownBannerInfo,
    willShowGoLive,
    isSpoLiveShowWithOpenSale,
    endSaleDate,
    isSaleOpen,
    schemaData
  } = useEventConfiguratorProperties(event, selectedVariant!, eventStatus!);
  const isLive = useMemo(() => eventStatus === 'LIVE', [eventStatus]);
  const hasAccess = isLive && hasTicketInShopify;
  const showPlayer = (!!selectedVariant?.stream?.dashUrl || !!selectedVariant?.stream?.hlsUrl) && hasAccess && !playerError;
  const showCountdownBanner = (willShowGoLive || isSpoLiveShowWithOpenSale) && (!loggedIn || loggedIn && !hasTicketInShopify) && endSaleDate && !hasAccess && !playerError;
  const showEventConfigurator = !hasTicketInShopify && !isShowRestrictedInCurrentCountry && !hasAccess && (eventStatus === 'FUTURE' || isLive && isSaleOpen);
  const showPlayerLoader = !playerError && hasAccess && !showPlayer;
  const showPlaceholder = !eventStatus && !showPlayer && !showPlayerLoader && !showCountdownBanner && !showEventConfigurator;
  const eventStart = selectedVariant?.eventStart ? new Date(selectedVariant.eventStart) : undefined;
  const duration = event?.variants?.[0]?.stream?.duration;
  useEffect(() => {
    if (selectedVariant && referrerUrl) {
      pushToElevarDataLayer({
        eventName: 'dl_view_item',
        auth: auth.token,
        ecommerce: {
          currencyCode: 'EUR',
          detail: {
            actionField: {
              list: window.location.pathname,
              action: 'detail'
            },
            products: [{
              id: event.id,
              name: event.title,
              brand: 'OnAir',
              category: event?.type,
              variant: selectedVariant?.title || event.title,
              price: selectedVariant.price?.eur?.toString(),
              compare_at_price: selectedVariant?.compareAtPrice?.eur?.toString() || '0.0',
              quantity: '1',
              list: referrerUrl,
              product_id: event.shopifyProductId,
              variant_id: selectedVariant.shopifyProductVariantId,
              image: event.poster.asset?.url
            }]
          }
        }
      });
      localStorage.removeItem('slug');
    }
  }, [event, selectedVariant, referrerUrl]);
  const streamUrlsError = useStreamUrlsCheck({
    isLive,
    hasAccess,
    stream
  });
  return <EventPageComponent event={event} eventsByTag={eventsByTag} isShowRestrictedInCurrentCountry={isShowRestrictedInCurrentCountry} partnerToAccessShowInCurrentCountry={partnerToAccessShowInCurrentCountry} isWatchTrailerParamSet={isWatchTrailerParamSet} loggedIn={loggedIn} blocks={event.blocks} showCountdownBanner={showCountdownBanner} countDownBannerInfo={countDownBannerInfo} showPlayer={showPlayer} showEventConfigurator={showEventConfigurator} showPlayerLoader={showPlayerLoader} onPlayerError={setPlayerError} error={playerError} showPlaceholder={showPlaceholder} hasAccess={hasAccess} hasTicketInShopify={hasTicketInShopify} isSaleOpen={isSaleOpen} eventStatus={eventStatus} doorsOpenDate={doorsOpenDate} stream={stream} duration={duration} eventStart={eventStart} selectedVariantSku={selectedVariant?.sku} schemaData={schemaData} backgroundImageProps={backgroundImageProps} trackingTitle={selectedVariant?.sku} streamUrlsError={streamUrlsError} data-sentry-element="EventPageComponent" data-sentry-component="EventPage" data-sentry-source-file="EventPage.tsx" />;
};