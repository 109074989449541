import type { FC } from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import { ButtonType } from '@/enums/ButtonType';
import { useGeoLocation } from '@/providers/leaf/geo-location';
import { ContentfulEvent } from '@/types/contentful';
import { combineCurrencyAndAmount } from '@/utils/i18n';
import { commonTexts } from '@/utils/messages/common';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import { removeCartLineItemsAction, updateCartLinesAction } from '../../../state/actions/cartActions';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import type { CartState } from '../../../state/reducers/cartSlice';
import type { CartEdge, NormalizedCartListItem } from '../../../types/cart';
import { createArrayTillN, ensure } from '../../../utils/array';
import Button from '../../Button';
import Dropdown from '../../dropdown/Dropdown';
import Icon from '../../Icon';
import LoadingSpinner from '../../LoadingSpinner';
import { MAX_ADD_ONS_ITEM_QUANTITY } from '../constants';
import { getCartItemCurrency } from '../utils';
type CartListItemProps = NormalizedCartListItem & {
  index: number;
  isLoading: boolean;
};
const CartListItem: FC<CartListItemProps> = ({
  productTitle,
  variantTitle,
  productId,
  shopifyVariantId,
  poster,
  eventStart,
  quantity,
  price,
  compareAtPrice,
  index,
  lineId,
  estimatedCost,
  isLoading = false,
  isAddOnsProduct,
  icons,
  sku,
  type
}) => {
  const dispatch = useAppDispatch();
  const {
    cart,
    status: cartStatus
  }: CartState = useAppSelector(state => state.cartSlice);
  const auth = useAppSelector(state => state.auth);
  const customerCountryCode = useGeoLocation();
  const hasDiscount = Number(price) < Number(compareAtPrice);
  const priceCurrency = cart && getCartItemCurrency(cart);
  const referrerUrl = localStorage.getItem('cartSlug');
  const removeCartItem = () => {
    const event = {
      shopifyProductId: lineId,
      title: productTitle,
      slug: productTitle
    } as ContentfulEvent;
    dispatch(removeCartLineItemsAction({
      queryVariables: {
        cartId: cart?.id,
        lineIds: [lineId],
        countryCode: customerCountryCode
      }
    }));
    pushToElevarDataLayer({
      eventName: 'dl_remove_from_cart',
      auth: auth.token,
      ecommerce: {
        currencyCode: 'EUR',
        remove: {
          actionField: {
            list: window.location.pathname
          },
          products: [{
            id: event.id,
            name: productTitle,
            brand: 'OnAir',
            category: type || 'Dummy category',
            variant: variantTitle || productTitle,
            price: price,
            compare_at_price: compareAtPrice || '0.0',
            quantity: '1',
            list: referrerUrl,
            product_id: productId?.toString(),
            variant_id: shopifyVariantId,
            image: poster.asset.url,
            url: window.location.href
          }]
        }
      }
    });
    localStorage.removeItem('cartSlug');
  };
  const handleUpdateQuantity = (newQuantityIndex: number) => {
    const addOnInCart = ensure(cart?.lines.edges.find((edge: CartEdge) => edge.node.id === lineId)).node;

    // Check if the selected quantity is between 1 to 5, to prevent the user from buying more than the established maximum.
    if (cart && newQuantityIndex >= 0 && newQuantityIndex <= MAX_ADD_ONS_ITEM_QUANTITY - 1) {
      const lines = [{
        quantity: newQuantityIndex + 1,
        id: addOnInCart.id,
        attributes: addOnInCart.attributes
      }];
      dispatch(updateCartLinesAction({
        cartId: cart.id,
        lines,
        shopifyVariantId,
        countryCode: customerCountryCode
      }));
    }
  };
  const posterImage = poster.asset.url;
  return <div style={{
    position: 'relative'
  }} data-sentry-component="CartListItem" data-sentry-source-file="CartListItem.tsx">
      {isLoading && <LoadingSpinner className="cart-list-item-loading" />}
      <div className={classNames('row pb-3 ', {
      'dark-row': !(index % 2),
      'remove-cart-pending': isLoading
    })}>
        <div className="d-flex col-sm-12 col-lg-9 col-md-8 event-logo-and-desc">
          <div className="cart-event-logo">
            {posterImage && <img src={poster.asset.url} alt={poster.alt} width={136} height={80} className="cart__product-image" />}
          </div>

          <div className="cart-item-description">
            <span className="cart-item-title body-title">
              {isAddOnsProduct ? variantTitle : productTitle}
            </span>{' '}
            {!isAddOnsProduct && <>
                <br />
                <span className="caption-text color-accent-light">
                  {!!icons?.length && icons.map(({
                type
              }) => <Icon key={`ticket-icon-${type}`} icon={type} className="me-1" />)}
                </span>
                <br />
                <span className="caption-text color-accent-light">
                  {eventStart && format(new Date(eventStart), 'PP HH:mm')}
                </span>
                <br />
              </>}
            <div className="d-md-flex d-none">
              <Button type={ButtonType.BUTTON_STYLED_IN_COMPONENT} onClick={removeCartItem} className="cart-item-btn cart-item-delete-btn uppercase-first-letter caption-text" icon="oa-trashcan" disabled={cartStatus === 'REMOVE_CART_LINES_PENDING'} data-sentry-element="Button" data-sentry-source-file="CartListItem.tsx">
                {commonTexts.remove}
              </Button>
            </div>
          </div>
        </div>
        <div className="small-screen-bottom d-flex mt-2 d-md-none ps-2">
          <Button type={ButtonType.BUTTON_STYLED_IN_COMPONENT} onClick={() => removeCartItem()} className="cart-item-btn cart-item-delete-btn uppercase-first-letter caption-text" icon="oa-trashcan" data-sentry-element="Button" data-sentry-source-file="CartListItem.tsx">
            {commonTexts.remove}
          </Button>
          <span className="cart-item-price body-text quantity">
            {isAddOnsProduct ? <Dropdown options={createArrayTillN(MAX_ADD_ONS_ITEM_QUANTITY)} selected={quantity - 1} selectCallback={handleUpdateQuantity} smallLabel className="quantity-dropdown" /> : quantity}
          </span>
          <div>
            {compareAtPrice && hasDiscount && !isAddOnsProduct && <span className="cart-item-price base-price body-text me-1">
                {combineCurrencyAndAmount(compareAtPrice, priceCurrency)}
              </span>}
            <span className="cart-item-price body-text">
              {combineCurrencyAndAmount(estimatedCost.subtotalAmount?.amount, priceCurrency)}
            </span>
          </div>
        </div>
        <div className="d-md-flex cart-item-price col-sm-1 centered-cols col-lg-1 col-md-1 d-none">
          {compareAtPrice && hasDiscount && <span className="base-price body-text">
              {combineCurrencyAndAmount(compareAtPrice, priceCurrency)}
            </span>}
          <span className="body-text">
            {combineCurrencyAndAmount(price, priceCurrency)}
          </span>
        </div>
        <div className="d-md-flex cart-item-quantity col-sm-2 text-left centered-cols col-lg-1 col-md-2 d-none ps-3">
          {isAddOnsProduct ? <Dropdown options={createArrayTillN(MAX_ADD_ONS_ITEM_QUANTITY)} selected={quantity - 1} selectCallback={handleUpdateQuantity} smallLabel className="quantity-dropdown" /> : <span className="body-text">{quantity}</span>}
        </div>
        <div className="d-md-flex cart-item-total col-sm-1 centered-cols col-lg-1 col-md-1 d-none">
          <span className="body-text">
            {combineCurrencyAndAmount(estimatedCost.totalAmount?.amount, priceCurrency)}
          </span>
        </div>
      </div>
    </div>;
};
export default CartListItem;