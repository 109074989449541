import type { Event as EventSchema, WithContext } from 'schema-dts';

import { Event, EventVariant } from '@/types/contentful';

// For better SEO score need to provide url without www and / at the end
// const BASE_URL = 'https://onair.events';

export const generateEventSchema = (
  { title, shortDescription, slug, poster, createdAt, seo, trailer }: Event,
  { price, eventStart, eventEnd, doorsOpen, options }: EventVariant,
  BASE_URL: string,
): WithContext<EventSchema> => {
  const eventURL = `${BASE_URL}/${slug}`;
  const name = `${title} ${
    Array.isArray(options)
      ? `- ${options.map((option) => option.value).join(' / ')}`
      : ''
  }`;
  return {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name,
    description: seo?.description.description,
    offers: {
      '@type': 'Offer',
      url: eventURL,
      price: price?.eur,
      priceCurrency: 'EUR',
      availability: 'https://schema.org/InStock',
      validFrom: eventStart,
    },
    // Interesting field to add in case we enable free events
    // isAccessibleForFree: true/false,
    image: [seo?.image?.url!],
    eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
    startDate: eventStart,
    endDate: eventEnd,
    doorTime: doorsOpen,
    // https://schema.org/EventStatusType
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'VirtualLocation',
      url: eventURL,
    },
    subjectOf: {
      '@type': 'VideoObject',
      name: `${title} Trailer`,
      description: shortDescription,
      duration: `PT${trailer?.duration}M`,
      thumbnailUrl: seo?.image?.url!,
      uploadDate: eventStart,
      contentUrl: trailer?.hlsUrl,
      embedUrl: eventURL,
    },
    performer: {
      '@type': 'MusicGroup',
      name: title,
      image: poster?.asset?.file?.url,
    },
    organizer: {
      '@type': 'Organization',
      name: 'On Air B.V.',
      url: BASE_URL,
    },
  };
};
