import React, { FC, ReactNode } from 'react';
import Icon from '../Icon';
export type EventSellingPointProps = {
  readonly title: string;
  readonly description: ReactNode;
  readonly icon: string;
};
const SellingPoint: FC<EventSellingPointProps> = ({
  title,
  description,
  icon
}) => <div className="event-selling-point container text-center p-2 p-md-3 pt-md-5" data-sentry-component="SellingPoint" data-sentry-source-file="SellingPoint.tsx">
    <div className="pt-0 pt-sm-2 d-flex  flex-row d-md-inline">
      <Icon className='mr-4 md:mr-0' icon={icon} data-sentry-element="Icon" data-sentry-source-file="SellingPoint.tsx" />
      <h5 className="section-title md:m-auto mt-md-3 my-auto leading-6 md:text-center text-left">{title}</h5>
    </div>
    <div className="event-selling-point__description body-text mt-2 mt-md-3 md:text-center text-left text-sm leading-4">
      {description}
    </div>
  </div>;
export default SellingPoint;